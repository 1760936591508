<template>
  <div class="file-view view h-100" v-if="file">
    <div class="row bg-extra-light h-100 overflow-auto">
      <div class="col-8 sticky-top bg-extra-light pt-3">
        <router-link-back to="/files">
          {{ file.title }}
        </router-link-back>
      </div>
      <div v-if="canShowFile" class="col-4 sticky-top bg-extra-light pt-3 text-right">
        <a class="btn btn-primary"
           :href="fileUrl(file)"
           target="_blank"
           :download="file.filename"
        >
          {{ $t('file_view.download_file') }}
        </a>
      </div>
      <div v-if="canShowFile" class="col-12 pb-3 text-center">
        <div ref="file_column" v-if="file?.type === 'application/pdf'">
          <vue-pdf-embed
              :source="fileUrl(file)"
              :width="fileColumnWidth"
          />
        </div>
        <img v-else-if="file?.type.startsWith('image/')"
             :src="fileUrl(file) || file.default_thumbnail"
             class="img-fluid"
             :alt="file.title">
        <video v-else-if="file?.type.startsWith('video/')"
               controls
               preload="auto"
               playsinline
               muted
               autoplay
               :poster="file.thumbnail"
               class="w-100"
        >
          <source
              :src="fileUrl(file)"
              :type="file.type"
          >
        </video>
        <template v-else-if="file?.type.startsWith('audio/')">
          <img :src="getDefaultThumbnail(file)" alt="" class="img-fluid default-thumbnail">
          <audio controls
                 class="w-100">
            <source
                :src="fileUrl(file)"
                :type="file.type"
            >
          </audio>
        </template>
      </div>
      <div v-else class="col-12 text-center">
        <img :src="getDefaultThumbnail(file)" class="img-fluid default-thumbnail" alt="">
        <br>
        {{ $t('file_view.file_type_not_supported') }}
        <br>
        <button class="btn btn-primary mt-3" @click="downloadFile(file)">{{ $t('file_view.download_file') }}</button>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapState} from "vuex";


import FileTypes from "@/mixins/file-types";

import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'
import RouterLinkBack from "@/components/RouterLinkBack";

export default {
  name: "FileView",
  mixins: [FileTypes],
  components: {
    RouterLinkBack,
    VuePdfEmbed
  },
  props: {
    fileId: {type: String, default: null}
  },
  data() {
    return {
      fileColumnWidth: 0,
      fileData: null,
      blob: null
    }
  },
  computed: {
    ...mapState({
      f: state => state.Api.Files.current
    }),
    ...mapGetters({
      findFile: "Api/FileReferences/find",
    }),
    fileReference() {
      return this.findFile(this.fileId, 'file_id')
    },
    file() {
      return this.fileReference?.file || this.f
    },
    canShowFile() {
      return this.$config.VIEW_IN_APP_MIME_TYPES
          .find(type => this.file?.type === type)
    }
  },
  methods: {
    ...mapActions({
      getFile: "Api/Files/view"
    }),
    setPDFViewerWidth() {
      this.fileColumnWidth = this.$refs.file_column?.clientWidth || 500
    },
    fileUrl(file) {
      return [this.$config.API_BASE_URL, file.file].join('/')
    },
    downloadFile(file) {
      saveAs(this.fileUrl(file), file.filename)
    }
  },
  mounted() {
    this.getFile({
      id: this.fileId
    })
        .catch(this.apiErrorHandler)

    this.$nextTick(() => this.fileColumnWidth = this.$refs.file_column?.clientWidth || 500)

    window.addEventListener('resize', this.setPDFViewerWidth)

  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setPDFViewerWidth)

  },
}
</script>
