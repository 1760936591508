<template>
  <div class="login view row justify-content-center align-items-center">
    <div class="col-11 col-sm-6 col-lg-5 col-xl-4">
      <h2 class="text-uppercase text-center mb-5">{{ $t('forgot_password.forgot_password') }}</h2>
      <form @submit.prevent="send">
        <input
            ref="username"
            autocomplete="username"
            v-model="username"
            :placeholder="$t('login.username')"
            class="mb-4 form-control"
        />
        <button class="btn btn-primary btn-block" type="submit">{{ $t('forgot_password.send') }}</button>
      </form>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import targetUrls from '@/mixins/target-urls.js'

export default {
  name: 'ForgotPassword',
  mixins: [targetUrls],
  data() {
    return {
      username: '',
      loading: false
    }
  },
  methods: {
    ...mapActions({
      forgotPassword: 'Api/Users/forgot-password',
    }),
    async send() {
      try {
        this.loading = true

        const target = this.targetUrlForRoute({ name: 'ResetPassword', params: { token: '__TOKEN__' } })

        await this.forgotPassword({
          username: this.username,
          target
        })

        this.$toast.success(
            this.$t('forgot_password.password_reset_sent', { email: this.username }),
            this.toastOptions,
        )
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
