<template>
  <div class="file-card" @click="$emit('click')">
    <img v-if="showDefaultImage" :src="defaultImage"
         class="card-img-top"
         alt="">
    <img :src="image"
         class="card-img-top"
         alt=""
         @load="showDefaultImage = false"
    >
    <div class="card-body">
      <h5 class="card-title">{{ title }}</h5>
      <div v-if="subtitle" class="small font-italic text-muted mb-3">{{ subtitle }}</div>
      <p class="card-text">
        <span v-for="tag in tags" :key="tag.id" class="tag">{{ tagPrefix }}{{ tag.name }}</span>
      </p>
      <div class="text-right">
        <slot/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "GeneralCard",
  props: {
    image: {
      type: String,
      default: ''
    },
    defaultImage: {
      type: String,
      default: ''
    },
    title: {type: String, default: ""},
    subtitle: {type: String, default: ""},
    tags: {type: Array, default: () => []},
    tagPrefix: {type: String, default: '#'},
  },
  data() {
    return {
      showDefaultImage: true
    }
  }
}
</script>

<style scoped>

</style>
