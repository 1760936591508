<template>
  <div class="not-available-view view row justify-content-center align-items-center">
    <div class="col-11 col-sm-6 col-lg-5 col-xl-4 text-center">
      <h2 class="text-uppercase mb-5">{{ $t('not_available.title') }}</h2>
      <p>{{ $t('not_available.body') }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NotAvailable',
}
</script>
