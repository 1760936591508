<template>
  <div class="file-list-item form-row" @click="$emit('click')">
    <div class="col-2 file-thumbnail-column">

      <img v-if="showDefaultImage" :src="defaultImage"
           class="img-fluid"
           alt="">
      <img :src="image"
           class="img-fluid"
           alt=""
           @load="showDefaultImage = false"
      >
    </div>
    <div class="col-9 file-content-column">
      <h5>{{ title }}</h5>
      <div v-if="subtitle" class="small font-italic text-muted mb-3">{{ subtitle }}</div>
      <span v-for="tag in tags" :key="tag.id" class="tag">{{ tagPrefix }}{{ tag.name }}</span>
    </div>
    <div class="col-1 text-right">
      <slot/>
    </div>
  </div>
</template>

<script>
export default {
  name: "GeneralListItem",
  props: {
    image: {
      type: String,
      default: '',
    },
    defaultImage: {
      type: String,
      default: '',
    },
    title: {type: String, default: ""},
    subtitle: {type: String, default: ""},
    tags: {type: Array, default: () => []},
    tagPrefix: {type: String, default: '#'},
  },
  data() {
    return {
      showDefaultImage: true
    }
  }
}
</script>

<style scoped>

</style>
