<template>
  <div v-if="!lock" class="statistics-form view">
    <div class="row h-100">
      <div :class="{'col-8': ['edit', 'new'].includes(action), 'col-12': action==='view' }"
           class="chart-preview-column h-100 overflow-auto">
        <div class="sticky-top bg-white pt-2">
          <router-link-back :to="{name: 'Statistics'}">
            {{ chart.name || $t('statistics_form.unnamed') }}
          </router-link-back>
        </div>
        <div v-if="action === 'view'" class="col text-right">
          <button class="btn btn-primary" @click="redirectToEdit">{{ $t('statistics_list.edit') }}</button>
        </div>

        <StatisticChart
            ref="statistic_chart"
            :data="reports"
            :report-fields="reportFields"
            :chart-config="chart"
            :dataset-id-key="labelForField(chart?.config?.chart_value)"
            :label="chart.name"
            :chart-type="chart.type"
        />
      </div>
      <div v-if="['edit', 'new'].includes(action)" class="col-4 chart-editor-column h-100 overflow-auto">
        <statistic-settings
            :report-fields="reportFields"
            :report-configurations="reportConfigs"
            :key-not-unique="keyNotUnique"
            v-model="chart"

            @save="onSave"
            @cancel="$router.push({name:'Statistics'}).catch(() => null)"
            @remove="onRemoveDataset"
        />
      </div>
    </div>

    <dirty-modal
        ref="dirty"
        :entity="chart"
        :title="$t('statistics_form.dirty.title')"
        :body="$t('statistics_form.dirty.body')"
        :save-label="$t('statistics_form.dirty.save')"
        :dont-save-label="$t('statistics_form.dirty.discard')"
        @save="onSave"
    />
  </div>
  <loading-screen v-else></loading-screen>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import StatisticChart from '@/components/Statistics/StatisticsChart'
import StatisticSettings from '@/components/Statistics/StatisticSettings'
import _cloneDeep from 'lodash/cloneDeep'
import RouterLinkBack from '@/components/RouterLinkBack'
import LoadingScreen from '@/components/LoadingScreen'
import DirtyModal from 'pixelstein-vue-app-package/src/vue2/PsModal/PsModalDirtyModal'
import { flattenFields } from 'paperclip-lib/src/report-configs/ReportConfigUtils'

export default {
  name: 'StatisticsForm',
  props: {
    chartId: { type: String, default: null },
    action: { type: String, default: 'edit' },
  },
  components: {
    LoadingScreen,
    RouterLinkBack,
    StatisticChart,
    StatisticSettings,
    DirtyModal,
  },
  data () {
    return {
      lock: true,
      chart: null,
    }
  },
  computed: {
    ...mapState({
      reportConfigs: state => state.Api.ReportConfigs.all,
      charts: state => state.Api.Charts.all,
    }),
    reportFields () {
      return this.chart.report_configs
          .flatMap(rc => flattenFields(rc.fields))
          .filter((field, idx, array) => array.findIndex(
              f => f?.options?.name === field?.options?.name) === idx)
    },
    keyNotUnique () {
      return this.charts.find(c => c.name === this.chart.name && c.id !== this.chart.id)
    },
    reports () {
      return this.reportConfigs
          .filter(rc => this.chart.report_configs.some(r => r.id === rc.id))
          .flatMap(rc => rc.reports)
    },
  },
  methods: {
    ...mapActions({
      getReportConfig: 'Api/ReportConfigs/view',
      getReportConfigs: 'Api/ReportConfigs/index',
      getChart: 'Api/Charts/view',
      addChart: 'Api/Charts/add',
      updateChart: 'Api/Charts/edit',
    }),
    labelForField (key) {
      return this.reportFields.find(field => field?.options?.name === key)?.options?.label
    },
    onSave () {
      if (this.keyNotUnique) {
        return
      }

      this.$refs?.dirty.acceptNavigation()

      if (this.chartId) {
        this.updateChart(this.chart)
            .then(() => this.$router.push({name: 'StatisticsEdit', params: { action: 'view', chartId: this.chartId }}))
      } else {
        this.addChart(this.chart)
            .then(chart => this.$router.push({name: 'StatisticsEdit', params: { action: 'view', chartId: chart.id }}))
      }
    },
    onRemoveDataset (e) {
      this.chart.config.datasets.splice(e, 1)
    },
    redirectToEdit () {
      this.$router.push({name: 'StatisticsEdit', params: { action: 'edit', chartId: this.chartId }}).catch(() => null)
    },
  },
  mounted () {
    this.getReportConfigs({
      limit: 100,
      $ignorePagination: true,
      contain: ['reports'],
    })

    if (this.chartId) {
      this.getChart({
        id: this.chartId,
        contain: ['report_configs'],
      })
          .then(chartConfig => {
            this.chart = chartConfig
          })
          .finally(() => {
            this.lock = false
          })
    } else {
      // new
      this.chart = {
        name: 'new chart',
        report_configs: [],
        type: 'bar',
        config: {},
      }

      this.lock = false
    }
  },
}
</script>
