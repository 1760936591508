import {mapMutations} from "vuex";

export default {
	data() {
		return {
			debugCounter: 0,
		}
	},
	methods: {
		...mapMutations({
			setDebug: "setDebug"
		}),
		startDebugMode() {
			this.debugCounter++;
			
			if (this.debugTimer) {
				window.clearTimeout(this.debugTimer)
			}
			
			if (this.debugCounter < 5) {
				this.debugTimer = window.setTimeout(() => {
					this.debugCounter = 0;
					this.setDebug(false)
				}, 1000)
			} else {
				this.$toast.open({
					type: "info",
					message: "DEBUG MODE ENABLED",
					position: this.$config.TOAST_POSITION,
				})
				
				this.setDebug(true);
			}
			
		}
	}
}
