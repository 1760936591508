import Vue from 'vue'
import Vuex from 'vuex'

import Api from './Api'
import { hasAllPermissions } from '@/utils/permissions.js'

Vue.use(Vuex)

function matchRoles(userRoles, routeRoles) {
  if (!routeRoles) {
    // route requires no special roles
    return true
  }
  
  if (!userRoles) {
    // user has no roles assigned
    return false
  }
  
  if (typeof userRoles === 'string') {
    userRoles = [userRoles]
  }
  
  if (typeof routeRoles === 'string') {
    routeRoles = [routeRoles]
  }
  
  return userRoles.some(userRole => routeRoles.includes(userRole))
}

export default new Vuex.Store({
  state: {
    debug: false,
    user: null,
    menuMinimized: false,
    embedded: false,
  },
  mutations: {
    setDebug: (state, debug) => state.debug = debug,
    setUser: (state, user) => state.user = user,
    setMenuMinimized: (
      state, menuMinimized) => state.menuMinimized = menuMinimized,
    setEmbedded: (state, embedded) => state.embedded = embedded,
  },
  actions: {
    isRouteAllowed: async (
      { state: $$state },
      route,
    ) => {
      if (route.meta?.requiresAuth && !$$state.user) {
        return false
      }
      
      if (!matchRoles($$state.user?.role, route.meta?.roles)) {
        return false
      }
      
      if (
        route.meta?.requiredPermissions
        && !hasAllPermissions($$state.user, route.meta?.requiredPermissions)
      ) {
        return false
      }
      
      return true
    },
  },
  modules: {
    Api,
  },
})
