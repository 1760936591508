<template>
  <div class="dashboard-module view">
    <div class="sticky-top bg-white row pb-1 pt-2">
      <div class="col-12">
        <search-bar class="my-2"
                    :placeholder="$t('dashboards.search')"
                    :debounceWait="700"
                    v-model="searchQuery"
        />
      </div>
    </div>

    <multi-layout-list :items="filteredDashboards"
                       az-sort-key="name"
                       date-sort-key="modified"
                       offset-to=".view"
                       :initial-scroll-pos="scrollPos"
                       :initial-page="page"
                       @scroll-to-end="page++"
    >
      <template #headerToolbar>

      </template>

      <template #static_masonery_item_before>
        <router-link to="/dashboards/new">
          <general-card
              :default-image="addDashboardThumbnail"
              :title="$t('dashboards.new_dashboard')"
          />
        </router-link>
      </template>
      <template #static_list_item_before>
        <general-list-item
            class="mb-2"
            :default-image="addDashboardThumbnail"
            :title="$t('dashboards.new_dashboard')"
            @click="$router.push('/dashboards/new')"
        />
      </template>

      <template #cardTemplate="{item}">
        <general-card
            :default-image="dashboardThumbnail"
            :title="item.name"
            @click="handleItemOption({value:'view'}, item)"
            class="mb-3"
        >
          <div @click.stop>
            <drop-down class="p-2 ml-3"
                       :options="itemOptions"
                       :drop-up="true"
                       :drop-left="shouldDropLeft(item.id)"
                       close-on-choose
                       :force-close="item.id !== dropDownItem"
                       @open="dropDownItem = item.id"
                       @click="handleItemOption($event, item)"
            >
              <svg-icon name="ellipsis"/>
            </drop-down>
          </div>
        </general-card>
      </template>
      <template #listItemTemplate="{item}">
        <general-list-item
            :default-image="dashboardThumbnail"
            :title="item.name"
            @click="handleItemOption({value:'view'}, item)"
        >
          <div @click.stop>
            <drop-down class="p-2 ml-3"
                       :options="itemOptions"
                       :drop-up="false"
                       drop-left
                       close-on-choose
                       :force-close="item.id !== dropDownItem"
                       @open="dropDownItem = item.id"
                       @click="handleItemOption($event, item)"
            >
              <svg-icon name="ellipsis"/>
            </drop-down>
          </div>
        </general-list-item>
      </template>
    </multi-layout-list>

    <confirmation-modal v-if="dashboardToDelete"
                        :modal-title="$t('dashboards.safe_delete.title', {dashboardname: dashboardToDelete.name})"
                        :modal-body="$t('dashboards.safe_delete.body')"
                        :modal-no="$t('dashboards.safe_delete.no')"
                        :modal-yes="$t('dashboards.safe_delete.yes')"
                        :noButtonClass="'btn btn-light justify-self-start'"
                        @close="dashboardToDelete = null"
                        @confirm="tryDeleteDashboard(dashboardToDelete)"
    />

    <div class="loading-modal" v-if="loading">
      <loading-screen/>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import SearchBar from '@/components/SearchBar.vue'
import DropDown from '@/components/DropDown.vue'
import RouterLinkBack from '@/components/RouterLinkBack.vue'
import GeneralListItem from '@/components/GeneralListItem.vue'
import GeneralCard from '@/components/GeneralCard.vue'
import MultiLayoutList from '@/components/MultiLayoutList.vue'
import { mapActions, mapState } from 'vuex'
import SvgIcon from 'paperclip-lib/src/components/SvgIcon'
import { ACTION_DELETE, ACTION_READ, ACTION_UPDATE, hasPermission } from '@/utils/permissions.js'
import dashboardThumbnail from '@/assets/defaultThumbnails/dashboard.svg'
import addDashboardThumbnail from '@/assets/defaultThumbnails/add_dashboard.svg'
import LoadingScreen from '@/components/LoadingScreen.vue'
import ConfirmationModal from '@/components/ConfirmationModal.vue'

export default defineComponent({
  name: 'DashboardModule',
  components: {
    ConfirmationModal,
    MultiLayoutList,
    GeneralCard,
    GeneralListItem,
    RouterLinkBack,
    DropDown,
    SearchBar,
    SvgIcon,
    LoadingScreen,
  },
  data () {
    return {
      searchQuery: '',
      loading: true,
      page: 1,
      itemsPerPage: 20,
      scrollPos: 0,
      allDashboards: [],
      dashboardThumbnail,
      addDashboardThumbnail,
      dropDownItem: null,
      dashboardToDelete: null,
    }
  },
  computed: {
    ...mapState({
      user: state => state.user,
    }),
    filteredDashboards () {
      if (this.searchQuery) {
        this.loading = true
        const filteredDashboards = this.allDashboards.filter(dashboard => dashboard.name.includes(this.searchQuery))
        setTimeout(() => this.loading = false, 1000)
        return filteredDashboards
      }
      return this.allDashboards
    },
    itemOptions () {
      const options = [
        {
          group: 'default',
          groupLabel: 'default',
          value: 'view',
          label: this.$t('dashboards.open_dashboard'),
          active: false,
        },
      ]

      if (hasPermission(this.user, 'Dashboards', ACTION_UPDATE)) {
        options.push({
          group: 'default',
          groupLabel: 'default',
          value: 'edit',
          label: this.$t('dashboards.edit_dashboard'),
          active: false,
        })
      }

      if (hasPermission(this.user, 'Dashboards', ACTION_DELETE)) {
        options.push({
          group: 'default',
          groupLabel: 'default',
          value: 'delete',
          label: this.$t('dashboards.delete_dashboard'),
          active: false,
        })
      }

      return options
    },
  },
  methods: {
    ...mapActions({
      loadDashboards: 'Api/ChartCollections/index',
      deleteDashboard: 'Api/ChartCollections/delete',
    }),
    shouldDropLeft (id) {
      if (document.getElementById(id) && document.getElementById(id).offsetLeft < 5) {
        return false
      }
      return true
    },
    async handleItemOption (action, dashboard) {
      switch (action.value) {
        case 'view':
          this.$router.push(['/dashboards', dashboard.id].join('/')).catch(() => null)
          break
        case 'edit':
          this.$router.push(['/dashboards', dashboard.id, 'edit'].join('/')).catch(() => null)
          break
        case 'delete':
          this.dashboardToDelete = dashboard
          break
      }
    },
    async tryDeleteDashboard (dashboard) {
      await this.deleteDashboard({ id: dashboard.id })
      this.dashboardToDelete = null
      this.allDashboards = await this.loadDashboards({ $ignorePagination: true })
    },
  },
  async mounted () {
    if (hasPermission(this.user, 'Dashboards', ACTION_READ)) {
      this.allDashboards = await this.loadDashboards({ $ignorePagination: true })
      setTimeout(() => this.loading = false, 1000)
    }
  },
})
</script>
