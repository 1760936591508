<template>
  <div class="dashboard-module view">
    <div class="sticky-top px-3 mx-n3 pb-1 pt-2 d-flex justify-content-between align-items-center">
      <router-link-back to="/dashboards">
        {{ $t('dashboards.new_dashboard') }}
      </router-link-back>
    </div>
    <form @submit.prevent="setName">
      <form-input
          type="text"
          v-model="name"
          :required="true"
      >
        <template #label>
          {{ $t('dashboards.name') }}
        </template>
      </form-input>
      <button class="btn btn-primary" :disabled="!name">{{ $t('dashboards.create') }}</button>
    </form>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { mapActions } from 'vuex'
import RouterLinkBack from '@/components/RouterLinkBack.vue'
import FormInput from '@pixelstein/ps-form/components/PsFormInput.vue'

export default defineComponent({
  name: 'DashboardCreate',
  components: { RouterLinkBack, FormInput },
  data () {
    return {
      name: '',
    }
  },
  methods: {
    ...mapActions({
      loadCharts: 'Api/Charts/index',
      createDashboard: 'Api/ChartCollections/add',
    }),
    async setName () {
      const dashboard = await this.createDashboard({ name: this.name })

      this.$router.replace(['/dashboards', dashboard.id, 'edit'].join('/')).catch(() => null)
    },
  },
  mounted () {
    this.loadCharts({ $ignorePagination: true })
  },
})
</script>
