<template>
  <div class="message-center-module view">
    <div class="sticky-top bg-white row pb-1 pt-2">
      <div class="col-12">
        <search-bar
            class="my-2"
            :placeholder="$t('device_management.search_placeholder')"
            :debounceWait="700"
            v-model="search"
            v-if="canSeeAllDevices"
        />
      </div>
    </div>

    <h2 class="h3 text-uppercase" v-html="$t('navigation.modules.device_management')"></h2>

    <multi-layout-list
        :items="uniqueUsers"
        az-sort-key="username"
        date-sort-key="created"
        offset-to=".view"
        sticky-toolbar
    >
      <template #listItemTemplate="{item}">
        <div class="form-row bg-extra-light py-5 px-3 mb-3">
          <div class="col-5 font-weight-bold">
            {{ item.username }}
          </div>
          <div class="col">
            {{ $t('device_management.role', { role: item.role }) }}
          </div>
          <div class="col">
            {{ $t('device_management.device_count', { count: userDevices(item.id).length }) }}
          </div>
          <div class="col-1 text-right">
            <drop-down class="p-2 ml-3"
                       :options="itemOptions"
                       :drop-up="false"
                       drop-left
                       close-on-choose
                       :force-close="item.id !== dropDownItem"
                       @open="dropDownItem = item.id"
                       @click="handleItemOption($event, item)"
            >
              <svg-icon name="ellipsis"/>
            </drop-down>
          </div>
        </div>
      </template>

      <template #cardTemplate="{item}">
        <div class="bg-extra-light file-card mb-3 p-5">
          <div class="card-body">
            <div class="font-weight-bold card-title">
              {{ item.username.split('@').join('&#8203;@') }}
            </div>
            <p class="card-text">
              {{ $t('device_management.role', item) }}
            </p>
            <p class="card-text">
              {{ $t('device_management.device_count', { count: userDevices(item.id).length }) }}
            </p>
            <div class="text-right">
              <drop-down class="p-2 ml-3"
                         :options="itemOptions"
                         :drop-up="true"
                         :drop-left="shouldDropLeft(item.id)"
                         close-on-choose
                         :force-close="item.id !== dropDownItem"
                         @open="dropDownItem = item.id"
                         @click="handleItemOption($event, item)"
              >
                <svg-icon name="ellipsis"/>
              </drop-down>
            </div>
          </div>
        </div>
      </template>
    </multi-layout-list>

    <edit-entity-modal
        v-if="showModal"
        :allowedDelete="false"
        :modal-title="$t('device_management.edit_devices', {user: clonedDevices[0].user.username})"
        :items="clonedDevices"
        :required-values="[]"
        :required-unique-values="[]"
        :unique-invalid-info="$t('device_management.device_name_must_be_unique')"
        :required-invalid-info="$t('device_management.device_name_invalid')"
        :add-new-btn-label="$t('device_management.edit.add_device_button')"
        :cancel-btn-label="$t('device_management.edit.cancel_button')"
        @showModal="showModal = $event"
    >
      <template #formInputSlot="{item}">
        <div class="col-6">
          <form-input
              :label="$t('device_management.edit.name_placeholder')"
              disabled
              :invalid-note="$t('device_management.edit.device_name_invalid')"
              v-model="item.name"
          />
        </div>
        <div class="col-6">
          <form-input
              :label="$t('device_management.edit.identifier_placeholder')"
              disabled
              v-model="item.identifier"
          />
        </div>
      </template>
    </edit-entity-modal>

    <div class="loading-modal" v-if="loading">
      <loading-screen/>
    </div>
  </div>
</template>

<script>
import _cloneDeep from 'lodash/cloneDeep'

import { mapActions, mapMutations, mapState } from 'vuex'

import FormInput from '@pixelstein/ps-form/components/PsFormInput'

import EditEntityModal from '@/components/EditEntityModal'

import MultiLayoutList from '@/components/MultiLayoutList'
import DropDown from '@/components/DropDown'
import SvgIcon from 'paperclip-lib/src/components/SvgIcon'

import SearchBar from '@/components/SearchBar'
import { ACTION_READ, ACTION_UPDATE, hasPermission, PERMISSION_ALL } from '@/utils/permissions.js'
import LoadingScreen from '@/components/LoadingScreen.vue'

export default {
  name: 'DeviceManagementModule',
  components: {
    FormInput,
    EditEntityModal,
    MultiLayoutList,
    DropDown,
    SvgIcon,
    SearchBar,
    LoadingScreen,
  },
  data () {
    return {
      search: '',
      clonedDevices: [],
      showModal: false,
      loading: false,
      dropDownItem: null,
    }
  },
  computed: {
    ...mapState({
      allDevices: state => state.Api.Devices.all,
      user: state => state.user,
    }),
    itemOptions () {
      const options = []

      if (hasPermission(this.user, 'Devices', ACTION_UPDATE)) {
        options.push({
          group: 'default',
          groupLabel: 'default',
          value: 'edit',
          label: this.$t('device_management.option.edit'),
          active: false,
        })
      }

      return options
    },
    filteredDevices () {
      const regex = new RegExp(this.search, 'i')
      this.loading = true
      const filteredDevices = this.allDevices.filter(
          device => !!(device.identifier?.match(regex) || device.name?.match(regex) ||
              device.user?.username?.match(regex) || device.user?.email?.match(regex)))
      setTimeout(() => this.loading = false, 1000)
      return filteredDevices
    },
    uniqueUsers () {
      let users = this.filteredDevices.map(device => device.user)
      return [...new Map(users.map(item => [item['id'], item])).values()]
    },
    canSeeAllDevices () {
      return hasPermission(this.user, 'Devices', ACTION_READ) === PERMISSION_ALL
    },
  },
  methods: {
    ...mapActions({
      getDevices: 'Api/Devices/index',
      editDevice: 'Api/Devices/edit',
      deleteDevice: 'Api/Devices/delete',
    }),
    ...mapMutations({
      resetDevices: 'Api/Devices/reset',
    }),
    shouldDropLeft (id) {
      if (document.getElementById(id) && document.getElementById(id).offsetLeft < 5) {
        return false
      }
      return true
    },
    handleItemOption (action, user) {
      switch (action.value) {
        case 'edit':
          this.clonedDevices = this.userDevices(user.id)
          this.showModal = true
          break
      }
    },
    userDevices (userId) {
      return _cloneDeep(this.filteredDevices)
          .filter(device => device.user_id === userId)
    },
    save () {
      const devicesToUpdate = this.clonedDevices.filter(dev => !dev.delete)
      const devicesToDelete = this.clonedDevices.filter(dev => dev.delete)

      Promise.all([
        devicesToUpdate.map(dir => this.editDevice(dir)),
        devicesToDelete.map(dir => this.deleteDevice(dir)),
      ])
          .then(() => {
            this.showModal = false
            this.clonedDevices = []
          })
    },
  },
}
</script>
