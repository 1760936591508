'use strict'

import ApiError from '@pixelstein/api-store-utils/src/errors/ApiError.js'

export default class ErrorFormatter {
  fallbackMessage
  translator
  
  constructor (translator = (message, options) => message) {
    this.translator = translator
    this.fallbackMessage = this.translator('fallback_error_message')
  }
  
  format (error) {
    if (typeof error === 'string') {
      return error
    } else if (error instanceof ApiError && error.issues) {
      const problems = Object.entries(error.issues)
        .map(([key, data]) => [
          this.translator(key),
          Object.values(data).map(d => this.translator(d)).join(', ')])
        .map(([key, data]) => `${key}: ${data}`)
        .join('\n')
      
      return this.translator('api_error_message', {
        name: error.name,
        message: error.message,
        problems,
      })
    }
    
    // no "else" so we can fall-through to here if an ApiError has no "issues"
    if (error instanceof Error) {
      return this.translator('error_message', {
        name: error.name,
        message: error.message,
      })
    } else {
      return this.fallbackMessage
    }
  }
}
