export default {
	created() {
		const cachedData = JSON.parse(window.sessionStorage.getItem(this.$route.path))
		if (cachedData !== null && typeof cachedData === "object") {
			Object.entries(cachedData).forEach(([key, value]) => {
				this.$data[key] = value
			})
		}
	},
	updated() {
    const cacheableKeys = this.cacheableKeys
    let cacheableData = this.$data
    
    if (Array.isArray(cacheableKeys)) {
      cacheableData = Object.entries(cacheableData)
        .filter(([key, value]) => cacheableKeys.includes(key))
        .reduce((acc, [key, value]) => ({...acc, [key]: value}), {})
    }
    
		window.sessionStorage.setItem(this.$route.path, JSON.stringify(cacheableData))
	}
}
