'use strict'

export function getParentWindow() {
  if (window !== window.parent) {
    return window.parent
  }
  
  if (window.opener) {
    return window.opener
  }
  
  return null
}

export function handleDelegatedLogin () {
  return new Promise(resolve => {
    const parent = getParentWindow()
    
    if (!parent) {
      resolve(null)
      
      return
    }
    
    const handler = event => {
      if (
        typeof event.data !== 'object'
        || event.data?.type !== 'AuthToken'
      ) {
        return
      }
      
      window.removeEventListener('message', handler)
      
      resolve(event.data.token)
    }
    
    window.addEventListener('message', handler)
    
    parent.postMessage({
      type: 'RequestAuthToken',
    }, '*')
    
    setTimeout(() => {
      window.removeEventListener('message', handler)
      
      resolve(null)
    }, 1000)
  })
}