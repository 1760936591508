<template>
  <div class="statistics-module view">
    <div class="sticky-top bg-white row pb-1 pt-2">
      <div class="col-12">
        <search-bar
            class="my-2"
            :placeholder="$t('statistics.search_in_statistics')"
            :debounceWait="700"
            v-model="searchQuery"
        />
      </div>
    </div>

    <template v-if="!loading">
      <multi-layout-list
          :items="filteredStatistics"
          az-sort-key="name"
          date-sort-key="modified"
          offset-to=".view"
          :initial-scroll-pos="scrollPos"
          :initial-page="page"
          @scroll-to-end="page++"
      >
        <template #headerToolbar>
          <div class="report-filters row mt-2">
            <div class="col">
              <form-select
                  :options="reportConfigOptions"
                  v-model="selectedReportConfigId"
              />
            </div>
          </div>
        </template>

        <template #static_masonery_item_before>
          <general-card
              :title="$t('statistics_list.add_new')"
              :default-image="addStatisticThumbnail"
              @click="handleItemOption({value:'new'})"
          />
        </template>

        <template #static_list_item_before>
          <general-list-item
              :title="$t('statistics_list.add_new')"
              :default-image="addStatisticThumbnail"
              @click="handleItemOption({value:'new'})"
          />
        </template>

        <template #cardTemplate="{item}">
          <general-card
              :default-image="statisticThumbnail"
              :title="item.name"
              :tags="reportConfigNames(item)"
              tagPrefix=""
              @click="handleItemOption({value:'view'}, item)"
              class="mb-3"
          >
            <div @click.stop>
              <drop-down class="p-2 ml-3"
                         :options="itemOptions"
                         :drop-up="true"
                         :drop-left="shouldDropLeft(item.id)"
                         close-on-choose
                         :force-close="item.id !== dropDownItem"
                         @open="dropDownItem = item.id"
                         @click="handleItemOption($event, item)"
              >
                <svg-icon name="ellipsis"/>
              </drop-down>
            </div>
          </general-card>
        </template>
        <template #listItemTemplate="{item}">
          <general-list-item
              :default-image="statisticThumbnail"
              :title="item.name"
              :tags="reportConfigNames(item)"
              tagPrefix=""
              @click="handleItemOption({value:'view'}, item)"
          >
            <div @click.stop>
              <drop-down class="p-2 ml-3"
                         :options="itemOptions"
                         :drop-up="false"
                         drop-left
                         close-on-choose
                         :force-close="item.id !== dropDownItem"
                         @open="dropDownItem = item.id"
                         @click="handleItemOption($event, item)"
              >
                <svg-icon name="ellipsis"/>
              </drop-down>
            </div>
          </general-list-item>
        </template>
      </multi-layout-list>

      <confirmation-modal
          v-if="statisticToDelete"
          :modal-title="$t('statistics_list.safe_delete.title', {statisticname: statisticToDelete.name})"
          :modal-body="$t('statistics_list.safe_delete.body')"
          :modal-no="$t('statistics_list.safe_delete.no')"
          :modal-yes="$t('statistics_list.safe_delete.yes')"
          :noButtonClass="'btn btn-light justify-self-start'"
          @close="statisticToDelete = null"
          @confirm="tryDeleteStatistic(statisticToDelete)"
      />
    </template>
    <loading-screen v-else/>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import SearchBar from '@/components/SearchBar'
import SvgIcon from 'paperclip-lib/src/components/SvgIcon'
import DropDown from '@/components/DropDown'
import MultiLayoutList from '@/components/MultiLayoutList'
import GeneralCard from '@/components/GeneralCard'
import GeneralListItem from '@/components/GeneralListItem'
import statisticThumbnail from '@/assets/defaultThumbnails/statistic.svg'
import LoadingScreen from '@/components/LoadingScreen.vue'
import FormRadio from '@pixelstein/ps-form/components/PsFormRadio.vue'
import FormSelect from '@pixelstein/ps-form/components/PsFormSelect'
import ConfirmationModal from '@/components/ConfirmationModal.vue'
import addStatisticThumbnail from '@/assets/defaultThumbnails/add_statistic.svg'

export default {
  name: 'ReportModule',
  components: {
    ConfirmationModal,
    FormRadio,
    MultiLayoutList,
    DropDown,
    SvgIcon,
    SearchBar,
    GeneralCard,
    GeneralListItem,
    LoadingScreen,
    FormSelect,
  },
  data () {
    return {
      searchQuery: '',
      lastSearchQuery: '',
      loading: false,
      page: 1,
      itemsPerPage: 20,
      scrollPos: 0,
      statisticThumbnail,
      addStatisticThumbnail,
      dropDownItem: null,
      selectedReportConfigId: null,
      statisticToDelete: null,
    }
  },
  computed: {
    ...mapState({
      allReportConfigs: state => state.Api.ReportConfigs.all,
      statistics: state => state.Api.Charts.all,
    }),
    filteredStatistics () {
      let filteredStatistics = this.statistics

      if (this.selectedReportConfigId) {
        filteredStatistics = filteredStatistics
            .filter(statistic => statistic.report_configs.some(config => config.id === this.selectedReportConfigId))
      }

      if (this.searchQuery !== this.lastSearchQuery) {
        this.loading = true

        const regex = new RegExp(this.searchQuery, 'i')
        this.lastSearchQuery = this.searchQuery

        filteredStatistics = filteredStatistics.filter(
            con => con.name.match(regex),
        )

        setTimeout(() => this.loading = false, 1000)
      }

      return filteredStatistics
    },
    reportConfigs () {
      if (this.searchQuery !== this.lastSearchQuery) {
        this.loading = true
      }

      const regex = new RegExp(this.searchQuery, 'i')
      this.lastSearchQuery = this.searchQuery

      const filteredReportConfigs = this.allReportConfigs.filter(
          con => con.campaign?.match(regex) || con.name.match(regex))
      setTimeout(() => this.loading = false, 1000)

      return filteredReportConfigs
    },
    reportConfigOptions () {
      return this.allReportConfigs
          .map(config => ({
            id: config.id,
            label: config.name,
          }))
    },
    itemOptions () {
      return [
        {
          group: 'default',
          groupLabel: 'default',
          value: 'view',
          label: this.$t('statistics_list.view'),
          active: false,
        },
        {
          group: 'default',
          groupLabel: 'default',
          value: 'edit',
          label: this.$t('statistics_list.edit'),
          active: false,
        },
        {
          group: 'default',
          groupLabel: 'default',
          value: 'delete',
          label: this.$t('statistics_list.delete'),
          active: false,
        },
      ]
    },
  },
  methods: {
    ...mapActions({
      deleteChart: 'Api/Charts/delete',
    }),
    reportConfigNames (statistic) {
      return statistic.report_configs
    },
    shouldDropLeft (id) {
      if (document.getElementById(id) && document.getElementById(id).offsetLeft < 5) {
        return false
      }

      return true
    },
    handleItemOption (action, statistic) {
      switch (action.value) {
        case 'new':
          this.$router.push({ name: 'StatisticsNew', params: { action: 'new' } })
              .catch(() => null)
          break
        case 'view':
          this.$router.push({
            name: 'StatisticsEdit',
            params: {
              action: 'view',
              chartId: statistic.id,
            },
          })
              .catch(() => null)
          break
        case 'edit':
          this.$router.push({
            name: 'StatisticsEdit',
            params: {
              action: 'edit',
              chartId: statistic.id,
            },
          })
              .catch(() => null)
          break
        case 'delete':
          this.statisticToDelete = statistic
      }
    },
    tryDeleteStatistic (chart) {

      this.deleteChart({
        id: chart.id,
      })

      this.statisticToDelete = null
    },
  },
}
</script>

