<template>
  <div class="login view row justify-content-center align-items-center">
    <div class="col-11 col-sm-6 col-lg-5 col-xl-4">
      <h2 class="text-uppercase text-center mb-5">{{ $t('confirm_invitation.confirm_invitation') }}</h2>
      <form @submit.prevent="send">
        <input
            type="password"
            autocomplete="new-password"
            v-model="password1"
            :placeholder="$t('confirm_invitation.password')"
            minlength="12"
            class="mb-4 form-control"
        />
        <input
            type="password"
            autocomplete="new-password"
            v-model="password2"
            :placeholder="$t('confirm_invitation.repeat_password')"
            minlength="12"
            class="mb-4 form-control"
        />
        <button class="btn btn-primary btn-block" type="submit">{{ $t('confirm_invitation.send') }}</button>
      </form>
    </div>
  </div>
</template>

<script>
import LoginOptions from 'pixelstein-vue-app-package/src/mixins/login-options'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'ConfirmInvitation',
  mixins: [LoginOptions],
  props: {
    token: String,
  },
  data () {
    return {
      password1: '',
      password2: '',
    }
  },
  computed: {
    ...mapState({
      authToken: state => state.Api.authToken,
    }),
  },
  methods: {
    ...mapActions({
      confirmInvitation: 'Api/Users/confirm-invitation',
      updateAuthToken: 'Api/updateAuthToken',
    }),
    redirect () {
      this.$router.push('/').catch(() => null)
    },
    async send () {
      if (this.password1 !== this.password2) {
        this.$toast.error(
            this.$t('confirm_invitation.errors.password_mismatch'),
            { position: this.$config.TOAST_POSITION },
        )

        return
      }

      try {
        const data = await this.confirmInvitation({
          token: this.token,
          password: this.password1,
          ...this.loginOptions,
        })

        await this.updateAuthToken(data.token)
      } catch (error) {
        this.$toast.error(
            this.$t('confirm_invitation.errors.not_valid'),
            { position: this.$config.TOAST_POSITION },
        )
      } finally {
        this.redirect()
      }
    },
  },
  created () {
    if (this.authToken) {
      this.redirect()
    }
  },
}
</script>
