<template>
  <div class="search-bar">
    <div class="input-group-prepend">
      <label v-if="value.length === 0" :for="'id_'+id" class="fa-solid fa-magnifying-glass mb-0"></label>
      <span v-else @click="$emit('input', '')" class="fas fa-times"></span>
    </div>
    <input type="text"
           :id="'id_'+id"
           ref="searchBar"
           class="form-control"
           :placeholder="placeholder"
           :disabled="disabled"
           :value="value"
           @input="debouncedEmit"
    >
  </div>
</template>

<script>
import {v1} from 'uuid'

export default {
  name: "SearchBar",
  props: {
    value: {type: String, default: ''},
    placeholder: {type: String, default: 'search..'},
    disabled: {type: Boolean, default: false},
    debounceWait: {type: Number, default: 200} // in milliseconds
  },
  data() {
    return {
      id: v1()
    }
  },
  methods: {
    debouncedEmit({target}) {
      if (this.timer) {
        window.clearTimeout(this.timer)
      }

      this.timer = window.setTimeout(() => {
        this.$emit('input', target.value)
      }, this.debounceWait)
    }
  }
}
</script>

