'use strict'

/**
 * @typedef {String} EntityName
 */

/**
 * The TableName is the plural of the EntityName
 * @typedef {String} TableName
 */

/**
 * @typedef {String} Action e.g. ACTION_CREATE, ACTION_READ
 */

/**
 * @typedef {String} PermissionLevel, e.g. PERMISSION_ALL, PERMISSION_SELF
 */

/**
 * @typedef {TableName[]} ArrayPermissions
 */

/**
 * @typedef {Object.<TableName, Action[]>} ObjectPermissions
 */

/**
 * @typedef {TableName} StringPermissions
 */

/**
 * @typedef {ArrayPermissions|ObjectPermissions|StringPermissions} Permissions
 */

/**
 * @typedef {Object} User
 * @property {Object.<EntityName, Object>} permissions
 */

const WILDCARD = '*'

export const ACTION_CREATE = 'create';
export const ACTION_READ = 'read';
export const ACTION_UPDATE = 'update';
export const ACTION_DELETE = 'delete';

export const PERMISSION_ALL = 'PolicyService__all';
export const PERMISSION_SELF = 'PolicyService__self';
export const PERMISSION_NONE = 'PolicyService__none';

const plurals = {}

/**
 * @param {string} name
 * @return {string}
 */
function pluralize(name) {
  // TODO: replace with a real inflector
  if (plurals[name]) {
    return plurals[name]
  } else if (name.endsWith('y')) {
    return name.substring(0, name.length-1) + 'ies'
  } else if (name.endsWith('s')) {
    return name + 'es'
  } else {
    return name + 's'
  }
}

/**
 * @param {string} singular
 * @param {string} plural
 */
export function registerPlural(singular, plural) {
  plurals[singular] = plural
}

function getPolicy(permissions, type, action) {
  if (!permissions || !type || !action) {
    return null
  }
  
  for (const entityName in permissions) {
    const tableName = pluralize(entityName.split(`\\`).pop())
    
    if (tableName !== type) {
      continue
    }
    
    return permissions[entityName][action] ?? permissions[entityName][WILDCARD]
  }
  
  return permissions[WILDCARD]?.[action] ?? permissions[WILDCARD]?.[WILDCARD]
}

export function hasPermission(user, store, action = ACTION_READ) {
  if (!user) {
    return undefined
  }
  
  const permission = getPolicy(user.permissions, store, action)
  
  if (permission === PERMISSION_NONE) {
    return undefined
  }
  
  return permission
}

/**
 *
 * @param {User} user
 * @param {Permissions} permissions
 * @return {boolean}
 */
export function hasAllPermissions(user, permissions) {
  if (Array.isArray(permissions)) {
    return permissions.every(model => !!hasPermission(user, model, ACTION_READ))
  } else if (typeof permissions === 'object') {
    return Object.entries(permissions).every(([model, perms]) => perms.every(perm => !!hasPermission(user, model, perm)))
  } else if (typeof permissions === 'string') {
    return !!hasPermission(user, permissions, ACTION_READ)
  } else {
    return true
  }
}