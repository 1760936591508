<template>
  <form v-if="reports" class="statistic-settings" @submit.prevent="$emit('save')">
    <h4 class="sticky-top bg-white m-0 py-3 mb-3 border-bottom">
      {{ $t('chart_settings.title') }}
    </h4>

    <form-input
        :label="$t('statistics.settings.title_label')"
        required
        :value="value.name"
        @input="onInput('name', $event)"
    />
    <div v-if="keyNotUnique" class="ps-form-partial-input-info mb-3">
      <span class="invalid-note invalid">{{ $t('statistics.invalid_name') }}</span>
    </div>

    <ps-accordion-collapse class="mb-4 mt-2 p-2 border">
      <template #header="{active}">
        <div class="d-flex justify-content-between align-items-center">
          {{ $t('chart_settings.forms') }}
          <i class="fas fa-chevron-left" :class="{active}" aria-hidden="true"></i>
        </div>
      </template>
      <template #content>
        <form-checkbox
            :options="reportConfigurationOptions"
            class="tag my-2"
            :required="value.report_configs.length === 0"
            :value="checkedReportConfigurations"
            @input="toggleReportConfig"
        />
      </template>
    </ps-accordion-collapse>

    <form-select
        :label="$t('statistics.settings.report_value')"
        required
        option-value-key="value"
        option-label-key="label"
        :options="reportValueOptions"
        :value="value.config.chart_value"
        @input="onInput('config.chart_value', $event)"
    />

    <form-radio
        v-if="reportFields.find(f => f?.options?.name === value.config.chart_value)?.options?.type === 'datetime-local'"
        class="tag mb-3"
        :required="!value.config.chart_value_format"
        option-value-key="value"
        option-label-key="label"
        :label="$t('statistics.settings.report_value_format')"
        :options="dateFormats"
        :value="value.config.chart_value_format || 'YYYY-MM-DD'"
        @input="onInput('config.chart_value_format', $event)"

    />

    <h5>{{ $t('statistics.settings.dataset_header') }}</h5>
    <ps-accordion-container>
      <ps-accordion-collapse
          v-for="(dataset, idx) in value.config.datasets"
          :key="dataset.id"
          :initial-active="idx === value.config.datasets.length-1"
          class="border border-light mb-2"
      >
        <template #header="{active}">
          <div class="p-2 d-flex justify-content-between align-items-center">
            {{ dataset.label || 'unnamed' }} <i class="fas fa-chevron-left" :class="{active}" aria-hidden="true"></i>
          </div>
        </template>
        <template #content>
          <statistics-settings-data-set
              class="border-top p-2"
              :report-fields="reportFields"
              :value="dataset"
              @input="onInput(['config','datasets', idx], $event)"
          />
          <button type="button"
                  class="btn btn-block btn-danger"
                  @click="$emit('remove', idx)"
          >
            {{ $t('statistics.settings.remove_dataset', { name: dataset.label || 'unnamed' }) }}
          </button>
        </template>
      </ps-accordion-collapse>
    </ps-accordion-container>

    <button type="button"
            class="btn btn-secondary btn-block mt-2"
            @click="addDataset"
    >
      {{ $t('statistics.settings.add_dataset') }}
    </button>


    <!-- Save / Cancel Toolbar -->
    <div class="form-row mt-5">
      <div class="col-6">
        <button type="button" class="btn btn-danger btn-block" @click="$emit('cancel')">
          {{ $t('statistic_settings.cancel') }}
        </button>
      </div>
      <div class="col-6">
        <button type="submit" class="btn btn-secondary btn-block">
          {{ $t('statistic_settings.save') }}
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import { v1 } from 'uuid'
import _cloneDeep from 'lodash/cloneDeep'
import _set from 'lodash/set'

import FormInput from '@pixelstein/ps-form/components/PsFormInput'
import FormSelect from '@pixelstein/ps-form/components/PsFormSelect'
import FormRadio from '@pixelstein/ps-form/components/PsFormRadio'
import FormCheckbox from '@pixelstein/ps-form/components/PsFormCheckbox'

import StatisticsSettingsDataSet from '@/components/Statistics/StatisticsSettingsDataSet'

import PsAccordionContainer from 'pixelstein-vue-app-package/src/vue2/PsAccordion/PsAccordionContainer'
import PsAccordionCollapse from 'pixelstein-vue-app-package/src/vue2/PsAccordion/PsAccordionCollapse'

export default {
  name: 'StatisticSettings',
  emits: ['input', 'cancel', 'remove', 'save'],
  components: {
    StatisticsSettingsDataSet,
    FormInput,
    FormSelect,
    FormCheckbox,
    FormRadio,
    PsAccordionContainer,
    PsAccordionCollapse,
  },
  props: {
    reportFields: { type: Array, default: () => [] },
    reportConfigurations: { type: Array, default: () => [] },
    value: { type: Object, default: () => ({}) },
    keyNotUnique: {type: Boolean, default: () => false },
  },
  data () {
    return {
      newFilterKey: '',
      filters: {},
    }
  },
  computed: {
    reportConfigurationOptions() {
      return this.reportConfigurations
          .map(config => ({ label: config.name, value: config.id }))
    },
    checkedReportConfigurations() {
      return this.value.report_configs
          .map(config => ({ [config.id]: true }))
          .reduce((accu, config) => ({ ...accu, ...config }), {})
    },
    reports () {
      if (!this.value) {
        return null
      }
      let reports = []
      this.value.report_configs.forEach(rc => reports = [...reports, ...(rc.reports || [])])
      return reports
    },
    chartTypes () {
      return [
        { label: this.$t('statistics.chart_types.bar'), id: 'bar' },
        // {label: this.$t("statistics.chart_types.pie"), id: "pie"},
        // {label: this.$t("statistics.chart_types.bubble"), id: "bubble"},
        // {label: this.$t("statistics.chart_types.doughnut"), id: "doughnut"},
        // {label: this.$t("statistics.chart_types.line"), id: "line"},
        // {label: this.$t("statistics.chart_types.polar_area"), id: "polar-area"},
        // {label: this.$t("statistics.chart_types.radar"), id: "radar"},
        // {label: this.$t("statistics.chart_types.scatter"), id: "scatter"},
      ]
    },
    dateFormats () {
      return [
        {
          value: this.$config.CHART_DATETIME_PER_DAY_FORMAT || 'YYYY-MM-DD',
          label: this.$t('statistics.settings.date.per_day'),
        },
        {
          value: this.$config.CHART_DATETIME_PER_MONTH_FORMAT || 'YYYY-MM',
          label: this.$t('statistics.settings.date.per_month'),
        },
        {
          value: this.$config.CHART_DATETIME_PER_WEEK_FORMAT || 'YYYY-W',
          label: this.$t('statistics.settings.date.per_week'),
        },
      ]
    },
    reportValueOptions () {
      return this.reportFields
          .map(field => ({
            label: field.options.label || field.options.name,
            value: field.options.name,
          }))
          .filter((field, idx, array) => array.findIndex(f => f.value === field.value) === idx)
          .filter(field => !!field.value)

    },
    mergedReportValues () {
      let mergedData = {}
      _cloneDeep(this.reports)
          .flatMap(report => Object.entries(report.data))
          .forEach(([key, value]) => {
            if (!mergedData[key]) {
              mergedData[key] = []
            }

            if (typeof value === 'object') {
              value = Object.keys(value)
            }

            mergedData[key].push(value)
            mergedData[key] = mergedData[key].flat(2)
            mergedData[key] = [...new Set(mergedData[key])]
                .filter(v => !!v)
                .sort((a, b) => {
                  console.log(a, b)
                  a.localeCompare(b)
                })
          })

      return mergedData
    },
  },
  methods: {
    labelForField (key) {
      return this.reportValueOptions.find(field => field.value === key)?.label
    },
    addDataset () {
      const nextIndex = this.value?.config?.datasets?.length || 0
      this.onInput(['config', 'datasets', nextIndex], { id: v1() })
    },
    toggleReportConfig (values) {
      const configs = _cloneDeep(this.reportConfigurations.filter(config => values[config.id]))

      this.onInput('report_configs', configs)
    },
    onInput (path, value) {
      let updatedData = _cloneDeep(this.value)
      updatedData = _set(updatedData, path, value)

      this.$emit('input', updatedData)
    },

  },
}
</script>
