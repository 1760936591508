<template>
  <div class="message-preview">
    <ps-sortable-list :items="messages" default-sort-key="modified" :default-sort-direction-a-s-c="false">
      <template #static_item_before>
        <slot name="static_message_item_before"/>
      </template>
      <template #itemTemplate="{item}">
        <div class="form-row">
          <div class="message col-12"
               :class="{active: item.id === value}"
               @click="$emit('input', item.id)"
          >
            <strong>{{ item.title }}</strong>
            <div class="small text-light">{{ toFormattedDateTime(item.created) }}</div>
            <div class="body-preview"
                 v-line-clamp="2"
                 v-html="item.body"></div>
          </div>
        </div>
      </template>
    </ps-sortable-list>

  </div>
</template>

<script>
import DateFormats from "pixelstein-vue-app-package/src/mixins/date-formats";

import PsSortableList from "pixelstein-vue-app-package/src/vue2/PsSortableList/PsSortableList"

export default {
  name: "MessagePreview",
  mixins: [DateFormats],
  components: {
    PsSortableList
  },
  props: {
    messages: {type: Array, default: () => []},
    value: {type: String, default: ''}
  }
}
</script>

<style scoped>

</style>
