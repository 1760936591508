<template>
  <div class="horizontal-scroll-wrap">
    <slot name="before"/>
    <div class="horizontal-scroll-outer-wrap">
      <div class="horizontal-scroll-inner-wrap">
        <slot/>
      </div>
    </div>
    <slot name="after"/>
  </div>
</template>

<script>
export default {
  name: "HorizontalScrollWrap"
}
</script>
